import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

const Metadata = ({ title, description, image, article }) => {

  const { pathname } = useLocation();

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            url
            image
            twitterUsername
          }
        }
      }
    `
  )
  const metaTitle = title || data.site.siteMetadata.title;
  const metaDescription = description || data.site.siteMetadata.description;
  const metaImage = image || data.site.siteMetadata.image;
  const metaUrl = `${data.site.siteMetadata.url}${pathname}`;
  const twitterUsername = data.site.siteMetadata.twitterUsername;

  return (
    <Helmet>
      <title>{`${metaTitle} | ${data.site.siteMetadata.title}`}</title>
      <meta name="description" content={metaDescription} />

      <meta name="image" content={metaImage} />
      {metaUrl && <meta property="og:url" content={metaUrl} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {metaTitle && <meta property="og:title" content={metaTitle} />}
      {metaDescription && (
        <meta property="og:description" content={metaDescription} />
      )}
      {metaImage && <meta property="og:image" content={metaImage} />}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {metaTitle && <meta name="twitter:title" content={metaTitle} />}
      {metaDescription && (
        <meta name="twitter:description" content={metaDescription} />
      )}
      {metaImage && <meta name="twitter:image" content={metaImage} />}

    </Helmet>
  )
}

export default Metadata;